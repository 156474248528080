import React from "react"

import { graphql, useStaticQuery } from "gatsby"
import PropTypes from "prop-types"

import GeneralText from "components/common/GeneralText"
import TransitionWrapper from "components/common/Layout"
import Opinions from "components/common/Opinions"
import Seo from "components/common/Seo"
import SubpageHeader from "components/common/SubpageHeader"
import TrustUs from "components/common/TrustUs"
import CheckBlog from "components/pages/about/CheckBlog"
import FullWidthImage from "components/pages/about/FullWidthImage"
import Management from "components/pages/about/Management"
import Principles from "components/pages/about/Principles"
import Team from "components/pages/about/Team"

const query = graphql`
  query aboutQuery {
    strapiAboutUs {
      page_title
      page_description
      group_photo {
        localFile {
          childImageSharp {
            gatsbyImageData(width: 2400, placeholder: TRACED_SVG)
          }
        }
      }
      management {
        manager {
          id
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 340, placeholder: TRACED_SVG)
              }
            }
          }
          description
          name
          title
        }
      }
      team {
        section_title
        placeholder {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 320, placeholder: TRACED_SVG)
            }
          }
        }
        team_members {
          id
          name
          position
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 320, placeholder: TRACED_SVG)
              }
            }
          }
        }
      }
      values {
        section_title
        values {
          name
          title
          desc
        }
      }
    }
  }
`

const About = ({ location }) => {
  const { strapiAboutUs } = useStaticQuery(query)
  return (
    <TransitionWrapper location={location}>
      <Seo seoPageName="About" />
      <SubpageHeader subpageTitle={strapiAboutUs.page_title} />
      <GeneralText aboutContentTxt={strapiAboutUs.page_description} />
      <Management data={strapiAboutUs.management} />
      <Team data={strapiAboutUs.team} />
      <CheckBlog />
      <Principles data={strapiAboutUs.values} />
      <Opinions />
      <FullWidthImage image={strapiAboutUs.group_photo.localFile} />
      <TrustUs />
    </TransitionWrapper>
  )
}

About.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
}

export default About
