import React, { useEffect, useRef, useState } from "react"

// possible to back to source version and use lottie-light when PR be merged https://github.com/chenqingspring/react-lottie/pull/90
import { Lottie } from "@alfonmga/react-lottie-light-ts"
import PropTypes from "prop-types"
import styled from "styled-components"

import useIntersect from "helpers/useIntersect"

const defaultOptions = {
  loop: false,
  autoplay: false,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
}

const StyledWrapper = styled.div`
  height: ${({ height }) => height}px;
  width: ${({ width }) => width}px;
  max-width: 100%;
  display: block;
  margin: 0 auto;
  align-self: start;
`

const RevealAnimation = ({ animationData, scale, delay, timeDelay }) => {
  const elementRef = useRef()
  const [playingState, setPlayingState] = useState("stopped")
  const [inView] = useIntersect(elementRef, { threshold: 0, rootMargin: delay ? `-${delay} 0%` : undefined })

  useEffect(() => {
    if (inView) setTimeout(() => setPlayingState("playing"), timeDelay)
  }, [inView])

  return (
    <StyledWrapper ref={elementRef} height={animationData.h * scale} width={animationData.w * scale}>
      <Lottie config={{ ...defaultOptions, animationData }} playingState={playingState} speed={1} />
    </StyledWrapper>
  )
}

RevealAnimation.defaultProps = {
  scale: 1,
  timeDelay: 0,
}

RevealAnimation.propTypes = {
  scale: PropTypes.number,
  animationData: PropTypes.string.isRequired,
  timeDelay: PropTypes.number,
  initialIconPosition: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
  }).isRequired,
  finalIconPosition: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
  }).isRequired,
}

export default RevealAnimation

// EVENTS:

// onComplete
// onLoopComplete
// onEnterFrame
// onSegmentStart
// you can also use addEventListener with the following events:
//
// complete
// loopComplete
// enterFrame
// segmentStart
// config_ready (when initial config is done)
// data_ready (when all parts of the animation have been loaded)
// data_failed (when part of the animation can not be loaded)
// loaded_images (when all image loads have either succeeded or errored)
// DOMLoaded (when elements have been added to the DOM)
// destroy
