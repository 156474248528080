import React, { useEffect, useMemo, useState } from "react"

import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { chunk } from "lodash"
import Carousel from "nuka-carousel"
import PropTypes from "prop-types"
import styled from "styled-components"

import Button from "components/common/Button"
import Section from "components/common/Section"
import urls from "config/urls"
import useWindowWidth from "helpers/useWindowWidth"
import { mq } from "styles/theme"

import TeamMember from "./_components/TeamMember"

const SIZE = {
  L: "l",
  M: "m",
  S: "s",
  XS: "xs",
  XXS: "xxs",
}

const ROWS_NUMBER = 3

const ELEMENTS_IN_ROW = {
  [SIZE.L]: 6,
  [SIZE.M]: 5,
  [SIZE.S]: 4,
  [SIZE.XS]: 3,
  [SIZE.XXS]: 2,
}

const BREAKPOINTS = {
  [SIZE.L]: mq.l,
  [SIZE.M]: mq.m,
  [SIZE.S]: mq.s,
  [SIZE.XS]: mq.xs,
  [SIZE.XXS]: 600,
}

const StyledTeamMembersBox = styled.div`
  display: grid;
  max-width: ${({ theme }) => theme.container.xl}px;
  margin: 0 auto;
  grid-template-columns: repeat(${ELEMENTS_IN_ROW[SIZE.L]}, auto);
  @media (max-width: ${BREAKPOINTS[SIZE.M]}px) {
    grid-template-columns: repeat(${ELEMENTS_IN_ROW[SIZE.M]}, auto);
  }
  @media (max-width: ${BREAKPOINTS[SIZE.S]}px) {
    grid-template-columns: repeat(${ELEMENTS_IN_ROW[SIZE.S]}, auto);
  }
  @media (max-width: ${BREAKPOINTS[SIZE.XS]}px) {
    grid-template-columns: repeat(${ELEMENTS_IN_ROW[SIZE.XS]}, auto);
  }
  @media (max-width: ${BREAKPOINTS[SIZE.XXS]}px) {
    grid-template-columns: repeat(${ELEMENTS_IN_ROW[SIZE.XXS]}, auto);
  }
`

const StyledJoinOurTeam = styled.div`
  position: relative;
  color: ${({ theme }) => theme.color.text.inverted};
  text-transform: uppercase;

  .gatsby-image-wrapper {
    position: initial;
  }
`

const StyledButtonWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0 7.5rem 4.5rem 7.5rem;

  ${({ theme }) => theme.mq.xs} {
    padding: 0 3.5rem 4.5rem;
  }
`

const StyledCarousel = styled(Carousel)`
  background: ${({ theme }) => theme.gradient.linear.cyan_dark_to_light};
`

const StyledCarouselControls = styled.div`
  background: ${({ theme }) => theme.gradient.linear.cyan_dark_to_light};
  min-height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const StyledDot = styled.button`
  border: none;
  opacity: ${({ active }) => (active ? 1 : 0.5)};
  background: ${({ theme }) => theme.color.white_000};
  border-radius: 100%;
  height: 15px;
  width: 15px;
  cursor: pointer;
  margin: 0 7px;
`

const carouselConfig = {
  autoplay: false,
  wrapAround: true,
  dragging: false,
  transitionMode: "fade",
  defaultControlsConfig: {
    nextButtonStyle: { display: "none" },
    prevButtonStyle: { display: "none" },
    pagingDotsStyle: { display: "none" },
  },
}

const Team = ({ data: { section_title, team_members, placeholder } }) => {
  const windowWidth = useWindowWidth()
  const [slide, setSlide] = useState(0)
  const [pageSize, setPageSize] = useState()
  const updatePageSize = () => {
    if (windowWidth > BREAKPOINTS[SIZE.M]) setPageSize(ELEMENTS_IN_ROW[SIZE.L] * ROWS_NUMBER - 1)
    if (windowWidth <= BREAKPOINTS[SIZE.M]) setPageSize(ELEMENTS_IN_ROW[SIZE.M] * ROWS_NUMBER - 1)
    if (windowWidth <= BREAKPOINTS[SIZE.S]) setPageSize(ELEMENTS_IN_ROW[SIZE.S] * ROWS_NUMBER - 1)
    if (windowWidth <= BREAKPOINTS[SIZE.XS]) setPageSize(ELEMENTS_IN_ROW[SIZE.XS] * ROWS_NUMBER - 1)
    if (windowWidth <= BREAKPOINTS[SIZE.XXS]) setPageSize(ELEMENTS_IN_ROW[SIZE.XXS] * ROWS_NUMBER - 1)
  }
  useEffect(updatePageSize, [windowWidth])

  const teamChunks = useMemo(() => chunk(team_members, pageSize), [pageSize])
  return (
    <Section animatedTitle color="graphite" padding="0">
      <h2>{section_title}</h2>
      <StyledCarousel {...carouselConfig} slideIndex={slide}>
        {teamChunks.map(teamChunk => (
          <StyledTeamMembersBox>
            {teamChunk.map(({ name, position, id, image }) => (
              <TeamMember
                key={id}
                imageSource={getImage(image && image.localFile)}
                memberName={name}
                memberPosition={position}
              />
            ))}
            <StyledJoinOurTeam>
              <GatsbyImage image={getImage(placeholder.localFile)} />
              <StyledButtonWrapper>
                <Button aria-label="Apply to JMR" color="purple" to={`${urls.careers.url}`}>
                  Apply
                </Button>
              </StyledButtonWrapper>
            </StyledJoinOurTeam>
          </StyledTeamMembersBox>
        ))}
      </StyledCarousel>
      <StyledCarouselControls>
        {teamChunks.map((_, index) => (
          <StyledDot active={index === slide} onClick={() => setSlide(index)} />
        ))}
      </StyledCarouselControls>
    </Section>
  )
}

Team.propTypes = {
  data: PropTypes.shape({
    section_title: PropTypes.string,
    team_members: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        position: PropTypes.string,
        id: PropTypes.string,
        image: PropTypes.shape({}),
      })
    ),
    placeholder: PropTypes.shape({
      localFile: PropTypes.shape({}),
    }),
  }).isRequired,
}

export default Team
