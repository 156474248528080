import React from "react"

import styled from "styled-components"

import Button from "components/common/Button"
import Section from "components/common/Section"
import urls from "config/urls"

const StyledCheckBlogBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ theme }) => theme.mq.xs} {
    padding: 0 2rem;
  }
`

const StyledSectionTitle = styled.h2`
  font-family: ${({ theme }) => theme.font.fatfrank};
  margin-right: 4rem;

  ${({ theme }) => theme.mq.xs} {
    margin: 0 !important;
  }
`

const CheckBlog = () => (
  <Section color="navy" titleMargin="0">
    <StyledCheckBlogBox>
      <StyledSectionTitle>Check our blog</StyledSectionTitle>
      <Button aria-label="Go to JMR blog" fat padding="15px 60px" to={urls.blog.url}>
        GO!
      </Button>
    </StyledCheckBlogBox>
  </Section>
)

export default CheckBlog
