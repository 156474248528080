import React from "react"

import { GatsbyImage } from "gatsby-plugin-image"
import PropTypes from "prop-types"
import styled from "styled-components"

import { hexToRgba } from "styles/theme"

const StyledMemberPosition = styled.p`
  font-size: 1.4rem;
  line-height: 2rem;
  font-weight: 300;
  transition: 0.25s ${({ theme }) => theme.transitions.easeInQuart};
  transform: translateY(35%);
`

const StyledMemberDescriptionBox = styled.div`
  padding: 1.25rem 0;
  background-color: ${({ theme }) => hexToRgba(theme.color.black, 0.41)};
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  text-align: center;
  transition: 0.25s ${({ theme }) => theme.transitions.easeInQuart};
  transform: translateY(35%);
`

const StyledTeamMemberTile = styled.div`
  position: relative;
  color: ${({ theme }) => theme.color.text.inverted};
  text-transform: uppercase;
  overflow: hidden;
  &:hover {
    ${StyledMemberDescriptionBox} {
      transform: translateY(0);
    }
    ${StyledMemberPosition} {
      transform: translateY(0);
    }
  }

  .gatsby-image-wrapper [data-main-image] {
    will-change: auto;
  }
`

const StyledMemberName = styled.p`
  font-size: 1.6rem;
  line-height: 2.2rem;
  font-weight: 900;
`

const TeamMember = ({ imageSource, imageDescription, memberName, memberPosition }) => (
  <StyledTeamMemberTile>
    <div>
      <GatsbyImage alt={imageDescription} image={imageSource} />
    </div>
    <StyledMemberDescriptionBox>
      <StyledMemberName>{memberName}</StyledMemberName>
      <StyledMemberPosition>{memberPosition}</StyledMemberPosition>
    </StyledMemberDescriptionBox>
  </StyledTeamMemberTile>
)

TeamMember.defaultProps = {
  imageDescription: "Meet our team",
}

TeamMember.propTypes = {
  imageSource: PropTypes.shape({}).isRequired,
  imageDescription: PropTypes.string,
  memberName: PropTypes.string.isRequired,
  memberPosition: PropTypes.string.isRequired,
}

export default TeamMember
