import React from "react"

import { GatsbyImage, getImage } from "gatsby-plugin-image"
import PropTypes from "prop-types"
import styled from "styled-components"

import Container from "components/common/Container"
import Section from "components/common/Section"

const StyledManagementTile = styled.div`
  overflow: hidden;
  &:not(:last-child) {
    box-shadow: inset 0px -11px 20px -15px rgba(0, 0, 0, 0.1);

    @media (max-width: 760px) {
      box-shadow: none;
    }
  }
`

const StyledContent = styled.div`
  max-width: ${({ theme }) => theme.container.l}px;
  width: ${({ reversed }) => (reversed ? "calc(100% + 12px)" : "100%")};
  margin: 0 auto;
  display: grid;
  grid-template-columns: ${({ reversed }) => (reversed ? "auto 340px" : "340px auto")};
  grid-column-gap: 2.5rem;
  grid-row-gap: 1.5rem;
  grid-template-rows: 80px 2fr;
  align-items: flex-start;
  min-height: 280px;
  @media (max-width: 760px) {
    grid-gap: 0;
    grid-template-rows: auto;
    grid-template-columns: ${({ reversed }) => (reversed ? "auto minmax(150px, 250px)" : "minmax(150px, 250px) auto")};
  }
`

const StyledGatsbyImage = styled(GatsbyImage)`
  max-width: 340px;
  grid-row: 1/3;
  grid-column: ${({ reversed }) => (reversed ? "2" : "unset")};

  @media (max-width: 760px) {
    grid-row: unset;
    max-width: 100%;
  }
`

const StyledManagementTitleBox = styled.div`
  text-transform: uppercase;
  color: ${({ theme }) => theme.color.text.inverted};
  text-align: ${({ reversed }) => (reversed ? "right" : "left")};
  min-width: 150px;

  h3 {
    font-size: 2rem;
    line-height: 3rem;
    font-weight: 900;
    padding-top: 3rem;
    @media (max-width: 760px) {
      padding-top: 0;
    }
  }

  p {
    font-size: 1.8rem;
    line-height: 2.6rem;
    font-weight: 300;
  }

  @media (max-width: 760px) {
    height: 100%;
    box-shadow: inset 0px -11px 20px -15px rgba(0, 0, 0, 0.1);
    padding: 0 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  grid-row: ${({ reversed }) => (reversed ? 1 : "unset")};
`

const StyledManagementDescription = styled.p`
  text-align: ${({ reversed }) => (reversed ? "right" : "left")};
  justify-self: ${({ reversed }) => (reversed ? "flex-end" : "unset")};
  color: ${({ theme }) => theme.color.text.inverted};
  padding: ${({ reversed }) => (reversed ? "0 0 3rem 3rem" : "0 3rem 3rem 0")};
  font-size: 1.4rem;
  line-height: 2.4rem;
  font-style: italic;
  max-width: 480px;

  @media (max-width: 760px) {
    grid-column: 1/3;
    max-width: unset;
    padding: 3rem;
  }
`

const StyledContainer = styled(Container)`
  position: relative;
  background: ${({ theme }) => theme.color.cyan_400};
  z-index: 0;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: -100%;
    height: 100%;
    width: 300%;
    background: ${({ theme }) => theme.color.cyan_400};
    z-index: -1;
  }
`

const StyledSection = styled(Section)`
  display: flex;
  justify-content: center;
  @media (max-width: 1200px) {
    display: block;
  }
`

const Management = ({ data }) => (
  <StyledContainer noPadding>
    <StyledSection color="cyan" padding="0">
      {data.manager.map(({ id, name, image, title, description }, index) => (
        <StyledManagementTile key={id}>
          <StyledContent reversed={index % 2}>
            <StyledGatsbyImage
              alt={name}
              image={getImage(image.localFile)}
              imgStyle={{ objectFit: "cover" }}
              reversed={index % 2}
              style={{ width: "100%" }}
            />
            {/* <StyledManagementTileContent reversed={index % 2}> */}
            <StyledManagementTitleBox reversed={index % 2}>
              <h3>{name}</h3>
              <p>{title}</p>
            </StyledManagementTitleBox>
            <StyledManagementDescription reversed={index % 2}>{description}</StyledManagementDescription>
            {/* </StyledManagementTileContent> */}
          </StyledContent>
        </StyledManagementTile>
      ))}
    </StyledSection>
  </StyledContainer>
)

Management.propTypes = {
  data: PropTypes.shape({
    manager: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        image: PropTypes.shape({}),
        title: PropTypes.string,
        description: PropTypes.string,
      }).isRequired
    ),
  }).isRequired,
}

export default Management
