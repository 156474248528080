import React from "react"

import styled from "styled-components"

import animationDataDiamond from "assets/animations/animation_diamond.json"
import animationDataRocket from "assets/animations/animation_rocket.json"
import animationDataShield from "assets/animations/animation_shield.json"
import animationDataTeam from "assets/animations/animation_team.json"
import Container from "components/common/Container"
import RevealAnimation from "components/common/RevealAnimation"
import Section from "components/common/Section"
import Slider from "components/common/Slider"
import useWindowWidth from "helpers/useWindowWidth"
import { mq } from "styles/theme"

const StyledSection = styled(Section)`
  background: url(/images/principlesBackground.svg);
  background-size: cover;
  background-repeat: no-repeat;

  ${({ theme }) => theme.mq.s} {
    background: ${({ theme }) => theme.gradient.linear.cyan_dark_to_light};
  }
`

const StyledContainer = styled.div`
  display: grid;
  grid-gap: 5rem;
  height: 100%;

  ${({ theme }) => theme.mq.s} {
    grid-gap: 0;
  }
`

const StyledRow = styled.div`
  display: grid;
  grid-template-columns: ${({ columns }) => columns};
  grid-gap: 2rem;
  align-items: start;

  ${({ theme }) => theme.mq.s} {
    grid-template-columns: auto;
  }
`

const StyledPrincipleTxtBox = styled.div`
  color: ${({ theme }) => theme.color.text.inverted};

  ${({ theme }) => theme.mq.s} {
    padding: 0 30px;
  }
`

const StyledPrincipleTitle = styled.h3`
  font-size: 2.4rem;
  line-height: 3rem;
  font-weight: 900;
  margin-bottom: 3rem;

  ${({ theme }) => theme.mq.s} {
    font-size: 2rem;
    text-align: center;
    max-width: 200px;
    margin: 0 auto;
  }
`

const StyledPrincipleContent = styled.p`
  font-size: 1.6rem;
  line-height: 2.3rem;

  ${({ theme }) => theme.mq.s} {
    text-transform: none;
    text-align: center;
    max-width: 400px;
    margin: 0 auto 3rem auto;
  }
`

const Principles = ({ data: { section_title, values } }) => {
  const windowWidth = useWindowWidth()
  const valuesObj = values.reduce((res, curr) => {
    res[curr.name] = curr
    return res
  }, {})

  return (
    <StyledSection color="cyan">
      <h2>{section_title}</h2>
      <Container>
        {windowWidth && windowWidth <= mq.s ? (
          <Slider>
            <StyledContainer>
              <RevealAnimation animationData={animationDataRocket} scale={0.6} />
              <StyledPrincipleTxtBox>
                <StyledPrincipleTitle>{valuesObj?.transparency?.title}</StyledPrincipleTitle>
                <StyledPrincipleContent>{valuesObj?.transparency?.desc}</StyledPrincipleContent>
              </StyledPrincipleTxtBox>
              <RevealAnimation animationData={animationDataDiamond} scale={0.7} timeDelay={400} />
              <StyledPrincipleTxtBox>
                <StyledPrincipleTitle>{valuesObj?.quality?.title}</StyledPrincipleTitle>
                <StyledPrincipleContent>{valuesObj?.quality?.desc}</StyledPrincipleContent>
              </StyledPrincipleTxtBox>
            </StyledContainer>
            <StyledContainer>
              <RevealAnimation animationData={animationDataShield} scale={0.6} />
              <StyledPrincipleTxtBox>
                <StyledPrincipleTitle>{valuesObj?.goals?.title}</StyledPrincipleTitle>
                <StyledPrincipleContent>{valuesObj?.goals?.desc}</StyledPrincipleContent>
              </StyledPrincipleTxtBox>
              <RevealAnimation animationData={animationDataTeam} scale={0.5} timeDelay={400} />
              <StyledPrincipleTxtBox>
                <StyledPrincipleTitle>{valuesObj?.collaboration?.title}</StyledPrincipleTitle>
                <StyledPrincipleContent>{valuesObj?.collaboration?.desc}</StyledPrincipleContent>
              </StyledPrincipleTxtBox>
            </StyledContainer>
          </Slider>
        ) : (
          <StyledContainer>
            <StyledRow columns="3fr 3fr 2fr 2fr">
              <RevealAnimation animationData={animationDataRocket} scale={0.8} />
              <StyledPrincipleTxtBox>
                <StyledPrincipleTitle>{valuesObj?.transparency?.title}</StyledPrincipleTitle>
                <StyledPrincipleContent>{valuesObj?.transparency?.desc}</StyledPrincipleContent>
              </StyledPrincipleTxtBox>
              <RevealAnimation animationData={animationDataDiamond} scale={0.7} timeDelay={400} />
              <StyledPrincipleTxtBox>
                <StyledPrincipleTitle>{valuesObj?.quality?.title}</StyledPrincipleTitle>
                <StyledPrincipleContent>{valuesObj?.quality?.desc}</StyledPrincipleContent>
              </StyledPrincipleTxtBox>
            </StyledRow>
            <StyledRow columns="1fr 1fr 1fr 1fr">
              <RevealAnimation animationData={animationDataShield} scale={0.7} />
              <StyledPrincipleTxtBox>
                <StyledPrincipleTitle>{valuesObj?.goals?.title}</StyledPrincipleTitle>
                <StyledPrincipleContent>{valuesObj?.goals?.desc}</StyledPrincipleContent>
              </StyledPrincipleTxtBox>
              <RevealAnimation animationData={animationDataTeam} scale={0.7} timeDelay={400} />
              <StyledPrincipleTxtBox>
                <StyledPrincipleTitle>{valuesObj?.collaboration?.title}</StyledPrincipleTitle>
                <StyledPrincipleContent>{valuesObj?.collaboration?.desc}</StyledPrincipleContent>
              </StyledPrincipleTxtBox>
            </StyledRow>
          </StyledContainer>
        )}
      </Container>
    </StyledSection>
  )
}

export default Principles
